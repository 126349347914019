import * as React from 'react';
import _forEach from 'lodash/forEach';

import AddressAutocomplete, {
  IAddressAutocomplete,
} from '../AddressAutocomplete';

let fields = [];

type ACProps = {
  className?: any;
  placeholder?: string;
  setFieldValue: (key: string, value: string) => void;
};

const CustomFieldAddressAutocomplete = (props: ACProps) => {
  const handleAddressChange = (addressField: IAddressAutocomplete) => {
    _forEach(addressField, (value, key) => {
      fields.push(key);
      // @ts-ignore
      props.setFieldValue(key, value);
    });
  };

  const handleAddressReset = () => {
    _forEach(fields, field => {
      props.setFieldValue(field, '');
    });
  };

  return (
    <AddressAutocomplete
      className={props.className}
      handlePlaceChanged={handleAddressChange}
      handleReset={handleAddressReset}
      placeholder={props.placeholder}
    />
  );
};

export default CustomFieldAddressAutocomplete;
